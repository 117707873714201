import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Typography,
  Container,
  Paper,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { clearAuthState, clearMessageError, login } from "../../redux/actions/authAction";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import CustomButton from "../../components/CustomButton";
import { colors } from "../../utils/colors";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const { loading, error, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const handleLogin = () => {
    // Validate email
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    setEmailError(!emailValid);

    // Only proceed with login if both email and password are valid
    if (emailValid) {
      dispatch(login(email, password, navigate));
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    // Clear message and error state when this component mounts
    dispatch(clearMessageError());
  }, [dispatch]);

  // dispatch(clearAuthState());

  return (
    <Grid
      container
      sx={{
        height: "90vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.bg_color,
      }}
    >
      <Paper
        elevation={4}
        sx={{ borderRadius: "20px", width: "80%", height: "80vh" }}
      >
        <Grid container sx={{ height: "100%" }}>
          {/* Left Column */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "160px",
                height: "160px",
                backgroundColor: "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Logo style={{ width: "140px", height: "140px" }} />
            </div>
          </Grid>

          {/* Right Column */}
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Container maxWidth="xs">
              <Typography variant="h4" sx={{ mb: 4 }}>
                Se connecter
              </Typography>

              {error && (
                <Typography color="error" variant="body1">
                  {error}
                </Typography>
              )}

              <TextField
                required
                fullWidth
                label="Email"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
                helperText={
                  emailError ? "Veuillez entrer une adresse e-mail valide." : ""
                }
              />

              <TextField
                required
                fullWidth
                label="Mot de passe"
                margin="normal"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />

              <CustomButton
                text="Se connecter"
                outlined={false}
                loading={loading}
                onClick={handleLogin}
                sx={{ mt: 4, width: "100%" }}
              />

              <Typography
                variant="body2"
                sx={{ textAlign: "center", cursor: "pointer", mt: 2 }}
                onClick={handleForgotPassword}
              >
                Mot de passe oublié ?
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default LoginScreen;
