import React from "react";
import {
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { useState } from "react";
// import SettingsScreen from './SettingsScreen';
import { colors } from "../../utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/authAction";

const SettingsScreen = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Retrieve user data from the store
  const user = useSelector((state) => state.auth.user.user);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.bg_color,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: "900px",
          padding: "32px",
          borderRadius: "16px",
        }}
      >
        {/* Tabs for navigation */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="params screen tabs"
          centered
        >
          <Tab label="Paramètre généraux" />
          {/* <Tab label="Gestion des utilisateurs" />
          <Tab label="Support" /> */}
          <Tab label="Se déconnecter" />
        </Tabs>

        {/* Content Box */}
        <Box sx={{ mt: 4 }}>
          {activeTab === 0 && (
            <Box>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Information utilisateur
              </Typography>

              {/* Information Fields */}
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    label="Nom"
                    variant="outlined"
                    fullWidth
                    value={user.last_name} // Last name from user object
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    label="Prénom"
                    variant="outlined"
                    fullWidth
                    value={user.first_name} // First name from user object
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={user.email} // Email from user object
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    label="Numéro de téléphone"
                    variant="outlined"
                    fullWidth
                    value={user.phone_number} // Phone number from user object
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>

              {/* Validation Button */}
              {/* <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    padding: "10px 40px",
                    borderRadius: "8px",
                    backgroundColor: "#E0E0E0",
                  }}
                >
                  Valider
                </Button>
              </Box> */}
            </Box>
          )}

          {/* {activeTab === 1 && (
            <Typography>Gestion des utilisateurs content goes here</Typography>
          )} */}
          {/* {activeTab === 2 && (
            <Typography>Support content goes here</Typography>
          )} */}
          {activeTab === 1 && (
            <Box>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Déconnexion
              </Typography>
              <Typography variant="body1" gutterBottom>
                Êtes-vous sûr de vouloir vous déconnecter ?
              </Typography>
              <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  size="large"
                  color="error"
                  onClick={handleLogout}
                  sx={{
                    padding: "10px 40px",
                    borderRadius: "8px",
                  }}
                >
                  Se déconnecter
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default SettingsScreen;
