import React, { useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { CircularProgress } from "@mui/material";
import useConversationsStore from "./useConversationsStore";
import CitationDisplay from "./Citation";

const SubProcessDisplay = ({
  subProcesses,
  isOpen,
  toggleOpen,
  messageId,
  documents,
  showSpinner = true,
}) => {
  const subQuestions = [];

  subProcesses?.forEach((subProcess, subProcessIndex) => {
    if (subProcess.metadata_map?.sub_question) {
      subQuestions.push({
        subQuestion: subProcess.metadata_map?.sub_question,
        subProcessIndex,
        subQuestionIndex: subQuestions.length,
      });
    } else if (subProcess.metadata_map?.sub_questions) {
      subProcess.metadata_map?.sub_questions.forEach((subQuestion) => {
        subQuestions.push({
          subQuestion,
          subProcessIndex,
          subQuestionIndex: subQuestions.length,
        });
      });
    }
  });

  return (
    <div key={`${messageId}-sub-process`} className="mt-4 w-full rounded">
      <div
        className="flex w-max cursor-pointer items-center rounded p-1 font-nunito text-sm text-gray-90 hover:bg-gray-00"
        onClick={() => toggleOpen()}
      >
        Visualisez l'avancement
        <div className="px-3 py-2">
          {isOpen ? (
            <ExpandMoreIcon />
          ) : (
            <ExpandMoreIcon className="-rotate-90" />
          )}
        </div>
      </div>

      {isOpen && (
        <>
          <div className="ml-4 border-l border-l-gray-30 pb-1 pl-4 font-nunito text-[11px] font-light text-gray-60">
            <div>Question Received</div>
            {subQuestions.length > 0 && (
              <div key={`${messageId}-sub-process`} className="text-gray-60">
                <div>
                  {subQuestions.map(
                    ({ subQuestion, subQuestionIndex, subProcessIndex }) => {
                      const hasCitations = !!subQuestion.citations;
                      return (
                        <div
                          key={`${messageId}-${subProcessIndex}-${subQuestionIndex}`}
                        >
                          Generated Sub Query #{subQuestionIndex + 1}{" "}
                          <div className="flex w-11/12 flex-col rounded border">
                            <div className="rounded-t border-b bg-gray-00 p-2 font-bold text-gray-90">
                              {subQuestion.question}
                            </div>
                            <div className="overflow-scroll p-2 text-[11px] font-light">
                              {subQuestion.answer}
                            </div>

                            {hasCitations && (
                              <div className="mr-2 flex w-full overflow-x-scroll pl-2">
                                {subQuestion.citations?.map(
                                  (citation, citationIndex) => {
                                    return (
                                      <CitationDisplay
                                        key={`${messageId}-${subProcessIndex}-${subQuestionIndex}-${citationIndex}`}
                                        citation={{
                                          chunk_position:
                                            citation.chunk_position,
                                          creation_date: citation.creation_date,
                                          doc_type: citation.doc_type,
                                          document_id: citation.document_id,
                                          file_name: citation.file_name,
                                          language: citation.language,
                                          long_title: citation.long_title,
                                          score: citation.score,
                                          section_title: citation.section_title,
                                          short_title: citation.short_title,
                                          total_chunks: citation.total_chunks,
                                          color: "yellow",
                                        }}
                                      />
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>

          {showSpinner && (
            <div className="ml-2.5 mt-1">
              <CircularProgress size={18} />
            </div>
          )}
          <div className="pb-2"></div>
        </>
      )}
    </div>
  );
};

export default SubProcessDisplay;
