import React, { useState, useEffect, forwardRef } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  CircularProgress,
  Autocomplete,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../utils/colors";
import { styles } from "../../utils/styles";
import HtmlViewer from "../../components/html-viewer/HtmlViewer";
import useDocumentStore from "../ChatScreen/components/useDocumentStore";
import {
  searchCitations,
  fetchAutocompleteResults,
  fetchRecentSearches,
  searchSuccess,
  resetSearchStore,
} from "../../redux/actions/searchAction";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import debounce from "lodash/debounce";
import { windowWidth } from "../../utils/utils";
import { fontSize } from "../../utils/fonts";

const CustomListboxComponent = forwardRef(function CustomListboxComponent(
  { isFetchingMore, children, ...rest },
  ref
) {
  return (
    <div {...rest} ref={ref}>
      {children}
      {isFetchingMore && ( // Show the loading indicator if fetching more data
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "8px",
          }}
        >
          <CircularProgress size={20} />
        </Box>
      )}
    </div>
  );
});

const SearchScreen = () => {
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.auth.user?.user?.id);
  const { selectedCitation, setSelectedCitation, setLoading } =
    useDocumentStore();
  const { citations, loading, error, recentSearches, autocompleteResults } =
    useSelector((state) => state.search);
  const [searchQuery, setSearchQuery] = useState("");
  const [offset, setOffset] = useState(0); // Offset for pagination
  // const [hasMore, setHasMore] = useState(true); // To check if there are more results to load
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  // Ref for the listbox
  const listboxRef = React.useRef();

  const handleCitationClick = (doc) => {
    const selectedCitation_ = {
      ...doc.metadata,
      text: doc.text,
      id: doc?.id,
    };
    setSelectedCitation(selectedCitation_);
    setLoading(true);
  };

  const handleSearch = async (event) => {
    if (event.key === "Enter") {
      setLoadingSearch(true); // Set loadingSearch to true before dispatching the search
      await dispatch(searchCitations(searchQuery, user_id));
      setOffset(0);
      setLoadingSearch(false); // Set loadingSearch to false after the search completes
    }
  };

  // Define the debounced version of handleSearchInputChange
  const debouncedHandleSearchInputChange = debounce((value) => {
    if (value && !loadingSearch) {
      // Fetch autocomplete suggestions based on input
      dispatch(fetchAutocompleteResults(value));
    }
  }, 500);

  const handleSearchInputChange = (event, value, reason) => {
    if (reason !== "createOption" && reason !== "reset") {
      // Only clear the input if the reason is "clear" or if the input is empty
      if (reason === "clear" || value === "") {
        setSearchQuery("");
      } else {
        setSearchQuery(value);
        // Call the debounced function for fetching autocomplete
        debouncedHandleSearchInputChange(value);
        setOffset(0);
      }
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight && !isFetchingMore) {
      setIsFetchingMore(true); // Set fetching indicator to true

      const newOffset = offset + 7;
      const previousScrollTop = scrollTop; // Save the current scroll position

      dispatch(fetchRecentSearches(user_id, newOffset)).then((results) => {
        if (results.length > 0) {
          setOffset(newOffset);

          // Restore the scroll position
          if (listboxRef.current) {
            listboxRef.current.scrollTop = previousScrollTop;
          }
        }
        setIsFetchingMore(false); // Set fetching indicator to false after fetching is complete
      });
    }
  };

  const handleAutocompleteSelect = (event, value) => {
    if (value) {
      // Dispatch the selected citation to Redux store
      dispatch(searchSuccess(value?.results));
    }
  };

  // useEffect(() => {
  //   dispatch(resetSearchStore());
  // }, []);

  return (
    <Box sx={{ backgroundColor: colors.bg_color, maxWidth: "93.1vw" }}>
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          borderRadius: styles.main_container_radius,
          overflow: "hidden",
        }}
      >
        <Grid container sx={{ height: "94vh" }}>
          {/* Left Section (Document List and Search) */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: "100%",
              borderRight: "1px solid #e0e0e0",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
            }}
          >
            <Autocomplete
              freeSolo
              options={autocompleteResults || []} // Show autocomplete results
              getOptionLabel={(option) => option.title || ""}
              onInputChange={handleSearchInputChange}
              onChange={handleAutocompleteSelect}
              inputValue={searchQuery}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Rechercher par question, mots-clés, etc."
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: "16px" }}
                  onKeyDown={handleSearch}
                />
              )}
              ListboxComponent={CustomListboxComponent}
              ListboxProps={{
                onScroll: handleScroll,
                style: { maxHeight: 200, overflow: "auto" },
                isFetchingMore: isFetchingMore,
                ref: listboxRef,
              }}
              renderOption={(props, option, index) => {
                const { key, ...rest } = props; // Destructure key from props

                return (
                  <ListItem key={Math.random()} {...rest}>
                    <Box sx={{ width: "90%" }}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          color: "#333",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {option.title}
                      </Typography>
                    </Box>
                  </ListItem>
                );
              }}
            />

            <List sx={{ overflowY: "auto", flexGrow: 1 }}>
              {loading || loadingSearch ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress size={40} thickness={4} />
                </Box>
              ) : error ? (
                <Typography color="error">{error}</Typography>
              ) : citations?.length === 0 ? (
                // Display the message when no citations are present
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    color: "lightgray",
                  }}
                >
                  <Typography variant="h7" sx={{ color: "lightgray" }}>
                    Aucun résultat trouvé. Veuillez effectuer une recherche.
                  </Typography>
                </Box>
              ) : (
                citations?.map((citation) => (
                  <ListItem
                    key={citation?.id}
                    onClick={() => handleCitationClick(citation)}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      padding: "8px 0",
                      backgroundColor:
                        selectedCitation?.id === citation?.id
                          ? colors.gray_0
                          : "transparent",
                    }}
                  >
                    <Box
                      sx={{
                        // width: "100px",
                        marginLeft: -windowWidth / 1500,
                        // backgroundColor:'red',
                        minWidth: windowWidth / 23,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Chip
                        label={citation.metadata.doc_type}
                        variant="outlined"
                        size="small"
                        sx={{
                          fontSize: fontSize.xsmall,
                          color: (() => {
                            switch (citation.metadata.doc_type) {
                              case "Arrêté":
                                return "#1976d2"; // Blue
                              case "Décret":
                                return "#388e3c"; // Green
                              case "Dahir":
                                return "#d32f2f"; // Red
                              default:
                                return "#555"; // Default color
                            }
                          })(),
                          borderColor: (() => {
                            switch (citation.metadata.doc_type) {
                              case "Arrêté":
                                return "#1976d2";
                              case "Décret":
                                return "#388e3c";
                              case "Dahir":
                                return "#d32f2f";
                              default:
                                return "#555";
                            }
                          })(),
                        }}
                      />
                    </Box>

                    <Box sx={{ width: "90%" }}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          color: "#333",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {citation.metadata.long_title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#666",
                          fontSize: "0.8rem",
                          marginBottom: "4px",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {citation.text}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "#999", fontSize: "0.75rem" }}
                      >
                        {(() => {
                          console.log(
                            "DATAXX",
                            citation.metadata.creation_date
                          );

                          // Check if the date is in ISO format (yyyy-mm-dd)
                          const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;

                          if (
                            isoDateRegex.test(citation.metadata.creation_date)
                          ) {
                            // Convert ISO date format to a human-readable format
                            const date = new Date(
                              citation.metadata.creation_date
                            );
                            const formattedDate = date.toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              }
                            );
                            return formattedDate;
                          }

                          // Handle cases where the date might be in dd/mm/yyyy format
                          const [day, month, year] =
                            citation.metadata.creation_date.split("/");
                          const formattedDate = new Date(
                            `${month}/${day}/${year}`
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          });
                          return formattedDate;
                        })()}
                      </Typography>
                    </Box>
                  </ListItem>
                ))
              )}
            </List>
          </Grid>

          {/* Right Section (Document Viewer) */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              padding: "16px", // Add padding for better alignment
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <HtmlViewer />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default SearchScreen;
