import React from "react";
import AssistantDisplay from "./AssistantDisplay";
import UserDisplay from "./UserDisplay";
import Citations from "./Citations";
// import { getCitations } from "../utils/citations";
import { ROLE } from "../../../utils/constants";
import { getCitations } from "../../../utils/utils";

export default function Message({
  message,
  index,
  messagesLength,
  documents,
  showLoading,
}) {
  const citations = getCitations(message);

  return (
    <div
      className={`${index === messagesLength - 1 ? "mb-4" : ""} flex flex-col`}
      key={`message-${message.id}`}
    >
      {message.role === ROLE.ASSISTANT ? (
        <AssistantDisplay
          message={message}
          key={`${message.id}-answer-${index}`}
          documents={documents}
        />
      ) : message.role === ROLE.USER ? (
        <UserDisplay
          message={message}
          key={`${message.id}-question-${index}-user`}
          showLoading={index === messagesLength - 1 ? showLoading : false}
        />
      ) : (
        <div>Sorry, there is a problem.</div>
      )}
      {citations.length > 0 && <Citations citations={citations} />}
    </div>
  );
}
