import React from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { isMobile } from "../utils/utils";
import { Typography } from "@mui/material";
import useDocumentStore from "../screens/ChatScreen/components/useDocumentStore";
import { fontSize } from "../utils/fonts";

const BackButton = () => {
  const navigate = useNavigate();

  const { selectedCitation, clear } = useDocumentStore();

  const handleBack = () => {
    console.log("HAERR");
    if (selectedCitation?.file_name) {
      console.log("HEROOO");
      // If a citation is selected, clear it instead of navigating back
      clear();
      navigate("/conversation");
    } else {
      // Otherwise, navigate back in history
      navigate(-1);
    }
  };

  return (
    <IconButton
      onClick={handleBack}
      className="text-gray-800 hover:text-blue-500 focus:text-gray-500"
      size="large"
      edge="start"
      aria-label="back"
      sx={{
        "&:hover": {
          backgroundColor: "transparent", // Ensuring hover background is transparent
        },
      }}
    >
      <ArrowBackIcon />
      {!isMobile() && (
        <Typography
          variant="h6"
          sx={{ fontSize: fontSize.medium, fontWeight: 16 }}
        >
          Retour
        </Typography>
      )}
    </IconButton>
  );
};

export default BackButton;
