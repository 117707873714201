import React, { useState } from "react";
import CitationDisplay from "./Citation"; // Adjust the import paths as needed
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Citations = ({ citations }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="mt-2 mb-2 ml-auto w-4/5 pr-1">
      <div
        className="mb-2 cursor-pointer text-xs underline flex items-center font-nunito"
        style={{ color: '#BE2165' }}
        onClick={() => setShow(!show)}
      >
        Références:
        <div className="px-2 py-2">
          {!show ? (
            <ArrowDropDownIcon />
          ) : (
            <ArrowDropUpIcon className="-rotate-90" />
          )}
        </div>
      </div>

      {!show && (
        <div className="mb-10 justify-center space-y-2">
          {citations.slice(0, 4).map((citation, index) => (
            <CitationDisplay key={index} citation={citation} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Citations;
