import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import SendIcon from "../../../assets/chat/send.png";
import DisabledSendIcon from "../../../assets/chat/disabledSend.png";
import {
  List,
  IconButton,
  Typography,
  TextField,
  Paper,
  Box,
  Grid,
  InputAdornment,
  Divider,
  Button,
  Menu,
  MenuItem,
  ToggleButton,
  CircularProgress,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { EventSourcePolyfill } from "event-source-polyfill";

// import SendIcon from "@mui/icons-material/Send";
import useMessages from "../../../hooks/useMessage";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GetAppIcon from "@mui/icons-material/GetApp";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";

import { api_subscription_key, backendUrl } from "../../../config";
import { MESSAGE_STATUS } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteConversations,
  fetchConversation,
  setMessages_rd,
  systemSendMessage,
} from "../../../redux/actions/conversationAction";
// import { RenderConversations } from "./RenderConversations";
import { store } from "../../../redux/store";
import { windowHeight } from "../../../utils/utils";
import RenderConversations from "./RenderConversations";
import { backendClient } from "../../../api/backend";
import useDocumentStore from "./useDocumentStore";
import useConversationsStore from "./useConversationsStore";
import { colors } from "../../../utils/colors";
import { fontSize } from "../../../utils/fonts";
import { useSnackbar } from "notistack";

// Custom Styled ToggleButton
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  backgroundColor: "#f0f0f0",
  color: "rgba(0, 0, 0, 0.87)",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "black",
  },
  padding: "6px 12px",
  "& .MuiToggleButton-label": {
    paddingTop: "6px",
  },
  "&:not(:first-of-type)": {
    borderRadius: theme.shape.borderRadius,
  },
  "&:first-of-type": {
    borderRadius: theme.shape.borderRadius,
  },
  border: "none",
  "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRight: "none",
  },
  "&.MuiToggleButtonGroup-grouped": {
    border: "none",
    "&:not(:first-of-type)": {
      marginLeft: 0,
    },
  },
  "&:hover": {
    backgroundColor: "#d6d6d6", // Slightly darker grey on hover for unselected buttons
  },
}));

const Chat = (props) => {
  //   const documents = useSelector((state) => state.documents.documents);
  const documents = [];

  const { conversationId } = props;

  const dispatch = useDispatch();
  const user_email = useSelector((state) => state.auth.user?.user?.email);
  const user_id = useSelector((state) => state.auth.user?.user?.id);
  const conversation = useSelector((state) => state.conversations.conversation);
  // const [selectedDocuments, setSelectedDocuments] = useState([]);
  const textFocusRef = useRef();
  const [userMessage, setUserMessage] = useState("");
  const [isMessagePending, setIsMessagePending] = useState(false);
  // const { userSendMessage, systemSendMessage, messages, setMessages } =
  //   useMessages(conversationId || "");
  const { userSendMessage, systemSendMessage, messages, setMessages } =
    useConversationsStore();

  const [filteredMessages, setfilteredMessages] = useState(null);
  const [searchText, setSearchText] = useState(null);
  // Add a loading state for export
  const [isExporting, setIsExporting] = useState(false);

  const handleTextChange = (event) => {
    setUserMessage(event.target.value);
  };

  const handleTextSearchChange = (event) => {
    setSearchText(event.target.value);
    const filteredMessages_ = messages.filter((x) =>
      x.content.includes(event.target.value)
    );
    setfilteredMessages(filteredMessages_);
  };

  const setSuggestedMessage = (text) => {
    setUserMessage(text);
    if (textFocusRef.current) {
      textFocusRef.current.focus();
    }
  };

  const eventSourceRef = useRef(null);
  const isSubmittingRef = useRef(false);

  // const parentScreen = location.state?.parent_screen;
  const [textInputRows, setTextInputRows] = useState(1);

  const [displayCitations, setDisplayCitations] = useState(false);
  const [isFetchingConversation, setIsFetchingConversation] = useState(false);

  const { setSelectedCitation, loading, setLoading, selectedCitation } =
    useDocumentStore();

  // Export related states
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const isFirstFetch = useRef(true);
  const hasFetchedMessages = useRef(false);
  const location = useLocation();
  const prevLocationRef = useRef(location.pathname);
  const { enqueueSnackbar } = useSnackbar();

  // Fetch conversation data when the component mounts
  useEffect(() => {
    const fetchConversationData = async () => {
      if (isFirstFetch.current && conversationId) {
        isFirstFetch.current = false;
        setIsFetchingConversation(true);

        const conversationData = await dispatch(
          fetchConversation(conversationId, user_id, true)
        );

        hasFetchedMessages.current = true; // Mark that messages were fetched
        if (conversationData?.messages?.length) {
          setMessages(conversationData.messages);
        }
        setIsFetchingConversation(false);
      }
    };

    fetchConversationData();
  }, [dispatch, conversationId, user_id, setMessages]);

  // Handle cleanup: deleting conversation when the component unmounts
  // useEffect(() => {
  //   return () => {
  //     console.log("TRIGGER", messages);
  //     console.log("TRIGGER loading", loading);
  //     console.log("TRIGGER isMessagePending", isMessagePending);
  //     console.log("TRIGGER filtredMessages", filteredMessages);
  //     console.log("TRIGGER hasFetchedMessages", hasFetchedMessages);
  //     if (messages.length === 0 && hasFetchedMessages.current && !isMessagePending && !loading) {
  //       dispatch(deleteConversations([conversationId], user_id));
  //     }
  //   };
  // }, [dispatch, hasFetchedMessages, messages, conversationId, user_id, loading, isMessagePending]);

  useEffect(() => {
    setSelectedCitation(null);
  }, []);

  const submit = useCallback(() => {
    if (!userMessage || !conversationId) {
      console.error("No user message or conversation ID provided.");
      return;
    }

    setIsMessagePending(true);
    userSendMessage(userMessage);

    const tokenPayload = backendClient.getToken();
    if (!tokenPayload) {
      console.error("No authentication token available.");
      setIsMessagePending(false);
      setUserMessage("");
      return;
    }

    const token = `${tokenPayload.token_type
      .charAt(0)
      .toUpperCase()}${tokenPayload.token_type.slice(1)} ${encodeURIComponent(
      tokenPayload.access_token
    )}`;

    const encodedUserMessage = encodeURIComponent(userMessage);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const messageEndpoint = `${backendUrl}api/conversation/${conversationId}/message?user_message=${encodedUserMessage}&timezone=${encodeURIComponent(
      timezone
    )}`;

    const events = new EventSourcePolyfill(messageEndpoint, {
      headers: {
        Authorization: token,
      },
    });

    console.log("---events", events);

    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      console.log("parsedDataNNNNN", parsedData);
      systemSendMessage(parsedData);
      // setfilteredMessages([...filteredMessages, parsedData]);
      setfilteredMessages(parsedData);
      // dispatch(systemSendMessage(parsedData));

      if (
        parsedData.status === MESSAGE_STATUS.SUCCESS ||
        parsedData.status === MESSAGE_STATUS.ERROR
      ) {
        console.log("DONNNNNE");
        events.close();
        setIsMessagePending(false);
        setDisplayCitations(true);
      }
    };

    events.onerror = (event) => {
      console.error("EventSource failed:", event);
      events.close();
      setIsMessagePending(false);
      console.log("ERROR SSE", event);

      if (event.status === 403) {
        console.error("Access forbidden (403).");
        // logout();
      }

      // Retry after a custom duration if needed
      // setTimeout(() => submit(), 1000); // Retry after 60 seconds
    };

    setUserMessage("");
  }, [
    conversationId,
    userMessage,
    userSendMessage,
    systemSendMessage,
    setfilteredMessages,
    setDisplayCitations,
  ]);

  // console.log("messages", messages);
  // Export functionality
  const handleExport = (format: "pdf" | "docx" | "email") => {
    // Set loading state to true
    setIsExporting(true);

    const checkedMessages = messages.reduce((result, message, index) => {
      if (message.checked) {
        console.log(
          "${messages[index - 1].content}",
          messages[index - 1].content
        );
        // Check if the previous message exists and its role is "user"
        if (index > 0 && messages[index - 1].role === "user") {
          // Add the previous user message with title
          result.push({
            id: messages[index - 1].id,
            content: `<strong>Utilisateur:</strong> ${
              messages[index - 1].content
            }`, // Add "User" title
          });
        }
        // Add the current checked message with title
        result.push({
          id: message.id,
          content: `<strong>Réponse Juridia:</strong> ${message.content}`, // Add "Juridia" title
        });
      }
      return result;
    }, []);

    console.log("user_emailXXX", user_email);

    backendClient
      .exportMessages(checkedMessages, format, user_email)
      .then(async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        // Adjust the file name based on the export format
        if (format === "email") {
          a.download = "export.eml"; // Use .eml extension for email
        } else {
          a.download = `export.${format}`; // Keep the original logic for pdf, docx, etc.
        }
        a.click();
        enqueueSnackbar(`Messages exportés en format ${format} !`, {
          variant: "success",
        });
      })
      .catch((err) => console.error(err))
      .finally(() => {
        // Set loading state to false
        setIsExporting(false);
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const [isEdited, setIsEdited] = useState(null);
  // useEffect(() => {
  //   console.log("EDITING MESSAGE", messages);
  //   setfilteredMessages(messages);
  // }, [isEdited]);

  return (
    <>
      <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
        <BackButton />

        {/* <span onClick={() => {
          console.log('messages', messages)
          console.log('filteredMessages', filteredMessages)
        }}>
          DEBUG
        </span> */}

        <Box sx={{ flexGrow: 1, ml: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Rechercher dans la conversation..."
            onChange={handleTextSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          color="primary"
          sx={{ marginLeft: 2 }}
        >
          <GetAppIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ "aria-labelledby": "basic-button" }}
        >
          {isExporting ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              <CircularProgress size={24} />
            </Box>
          ) : (
            [
              <MenuItem key="pdf" onClick={() => handleExport("pdf")}>
                <PictureAsPdfIcon className="mr-2" /> PDF
              </MenuItem>,
              <MenuItem key="docx" onClick={() => handleExport("docx")}>
                <DescriptionIcon className="mr-2" /> DOCX
              </MenuItem>,
              <MenuItem key="email" onClick={() => handleExport("email")}>
                <EmailIcon className="mr-2" /> EMAIL
              </MenuItem>,
            ]
          )}
        </Menu>
      </Box>

      <Divider />

      {/* Messages List */}
      <List sx={{ overflowY: "auto", flexGrow: 1, padding: 0 }}>
        {/* <Typography variant="subtitle1" sx={{ p: 2 }}>
          Début de la conversation
        </Typography> */}
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{
            p: 2,
            fontWeight: 16,
            fontSize: fontSize.medium,
            color: "text.secondary", // MUI default text color
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "::before": {
              content: '""',
              flex: 1,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`, // MUI default divider color
              marginRight: "10px",
            },
            "::after": {
              content: '""',
              flex: 1,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`, // MUI default divider color
              marginLeft: "10px",
            },
          }}
        >
          Début de la conversation
        </Typography>

        {filteredMessages?.length > 0 ? (
          <RenderConversations
            messages={filteredMessages}
            setUserMessage={setSuggestedMessage}
            isFetchingConversation={isFetchingConversation}
            documents={[]}
          />
        ) : searchText?.length > 0 ? (
          <Typography variant="subtitle1" sx={{ p: 2 }}>
            No message found.
          </Typography>
        ) : (
          <RenderConversations
            messages={messages}
            setUserMessage={setSuggestedMessage}
            isFetchingConversation={isFetchingConversation}
            documents={[]}
          />
        )}
      </List>

      <Divider />

      {/* Message Input Section */}
      <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Écrire votre message ici..."
          multiline
          minRows={textInputRows}
          maxRows={4}
          value={userMessage}
          onChange={handleTextChange}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !event.altKey) {
              event.preventDefault();
              if (!isMessagePending && userMessage.trim()) submit();
            } else if (event.altKey && event.key === "Enter") {
              setUserMessage((prev) => `${prev}\n`);
            }
          }}
          ref={textFocusRef}
        />
        <IconButton
          onClick={submit}
          disabled={isMessagePending || !userMessage.trim()}
          sx={{ ml: 2 }}
        >
          <img
            src={
              isMessagePending || !userMessage.trim()
                ? DisabledSendIcon
                : SendIcon
            }
            alt="Send"
            style={{ height: "40px" }}
            // className="h-6 w-6" // Adjusted size for better UI
          />
        </IconButton>
      </Box>
    </>
  );
};

export default Chat;
