import { create } from "zustand";
import { isMobile } from "../../../utils/utils";

const useDocumentStore = create((set) => ({
  loading: false,
  setLoading: (loading) => set({ loading }),
  selectedCitation: {
    long_title: "",
  },
  setSelectedCitation: (citation) => set({ selectedCitation: citation }),
  displayCitations: false,
  setDisplayCitations: (display) => set({ displayCitations: display }),
  isModalOpen: false,
  setIsModalOpen: (open) => set({ isModalOpen: open }),
  showModalIfMobile: () => {
    if (isMobile()) {
      set({ isModalOpen: true });
    }
  },
  clear: () =>
    set({
      selectedCitation: {
        long_title: "",
      },
      isModalOpen: false, // Ensure modal is closed when clearing
    }),
}));

export default useDocumentStore;
