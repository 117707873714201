export const RESET_STORE = "RESET_STORE";

// AUTH ACTION TYPES
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const CLEAR_AUTH_STATE = "CLEAR_AUTH_STATE";
export const CLEAR_MESSAGE_ERROR = "CLEAR_MESSAGE_ERROR";

// USER ACTION TYPES
export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

// SEARCH
export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAILURE = "SEARCH_FAILURE";
export const FETCH_RECENT_SEARCHES = "FETCH_RECENT_SEARCHES";
export const FETCH_AUTOCOMPLETE_RESULTS = "FETCH_AUTOCOMPLETE_RESULTS";
export const RESET_SEARCH_STORE = "RESET_SEARCH_STORE";

// CONVERSATION ACTION TYPES
export const FETCH_CONVERSATIONS_REQUEST = "FETCH_CONVERSATIONS_REQUEST";
export const FETCH_CONVERSATIONS_SUCCESS = "FETCH_CONVERSATIONS_SUCCESS";
export const FETCH_CONVERSATIONS_FAILURE = "FETCH_CONVERSATIONS_FAILURE";
export const FETCH_CONVERSATION_REQUEST = "FETCH_CONVERSATION_REQUEST";
export const FETCH_CONVERSATION_SUCCESS = "FETCH_CONVERSATION_SUCCESS";
export const FETCH_CONVERSATION_FAILURE = "FETCH_CONVERSATION_FAILURE";
export const CREATE_CONVERSATION_REQUEST = "CREATE_CONVERSATION_REQUEST";
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS";
export const CREATE_CONVERSATION_FAILURE = "CREATE_CONVERSATION_FAILURE";
export const SEARCH_CONVERSATIONS_REQUEST = "SEARCH_CONVERSATIONS_REQUEST";
export const SEARCH_CONVERSATIONS_SUCCESS = "SEARCH_CONVERSATIONS_SUCCESS";
export const SEARCH_CONVERSATIONS_FAILURE = "SEARCH_CONVERSATIONS_FAILURE";
export const RESET_CONVERSATION = "RESET_CONVERSATION";

export const SET_CONVERSATION = "SET_CONVERSATION";
export const DELETE_CONVERSATION = "DELETE_CONVERSATION";
export const SHARE_CONVERSATION_SUCCESS = "SHARE_CONVERSATION_SUCCESS";

// MESSAGE ACTION TYPES
export const SET_MESSAGES = "SET_MESSAGES";
export const USER_SEND_MESSAGE = "USER_SEND_MESSAGE";
export const SYSTEM_SEND_MESSAGE = "SYSTEM_SEND_MESSAGE";

// NAVBAR ACTION TYPES
// Action Types
export const OPEN_NAVBAR = "OPEN_NAVBAR";
export const SET_OPEN_NAVBAR = "SET_OPEN_NAVBAR";
