import React from "react";
import SubProcessDisplay from "./SubProcessDisplay";
// import useConversationsStore from "../context/conversationsStore"; // Adjust the import paths as needed
// import { TextDirection } from "../types/conversation"; // Adjust the import paths as needed
// import { formatDisplayDate } from "../utils/timezone"; // Adjust the import paths as needed
import { TextDirection } from "../../../utils/constants";
import { formatDisplayDate } from "../../../utils/timezone";
import useConversationsStore from "./useConversationsStore";

const UserDisplay = ({ message, showLoading }) => {
  const { currentLanguage } = useConversationsStore();
  // console.log('message_2', message)

  return (
    <>
      <div className="flex border-r bg-gray-00 pb-4">
        <div
          className={`mt-4 w-1/5 font-nunito text-gray-60 
            ${
              ""
              // currentLanguage?.textDirection === TextDirection.RightToLeft
              //   ? "order-2"
              //   : ""
            }
          `}
        >
          <div
            onClick={() => console.log("messageXXX", message.created_at)}
            className="ml-auto w-28 pl-1"
            style={
              {
                // direction:
                //   currentLanguage?.textDirection === TextDirection.RightToLeft
                //     ? "rtl"
                //     : "ltr",
              }
            }
          >
            {formatDisplayDate(message.created_at)}
          </div>
        </div>
        <div
          className={`mt-4 w-4/5 ${
            // currentLanguage?.textDirection === TextDirection.RightToLeft
            //   ? "pl-3 text-right"
            //   :
            "pr-3 ml-[-4px]"
          }  font-nunito font-bold text-gray-90`}
        >
          {message.content}
        </div>
      </div>
      {showLoading && (
        <div className="flex border-b-2 pb-4">
          <div
            className={`w-1/5 ${
              // currentLanguage?.textDirection === TextDirection.RightToLeft
              //   ? "order-2"
              //   :
              ""
            }`}
          ></div>
          <div className="w-4/5">
            <SubProcessDisplay
              key={`${message.id}-loading-sub-process`}
              messageId={message.id}
              subProcesses={[]}
              isOpen={true}
              toggleOpen={() => null}
              showSpinner={true}
              documents={[]}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UserDisplay;
