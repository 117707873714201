import { backendClient } from "../../api/backend";
import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  FETCH_RECENT_SEARCHES,
  FETCH_AUTOCOMPLETE_RESULTS,
  RESET_SEARCH_STORE,
} from "./types";

export const resetSearchStore = () => ({ type: RESET_SEARCH_STORE });
export const searchRequest = () => ({ type: SEARCH_REQUEST });
export const searchSuccess = (documents) => ({
  type: SEARCH_SUCCESS,
  payload: documents,
});
export const searchFailure = (error) => ({
  type: SEARCH_FAILURE,
  payload: error,
});

export const fetchRecentSearchesSuccess = (searches) => ({
  type: FETCH_RECENT_SEARCHES,
  payload: searches,
});
export const fetchAutocompleteResultsSuccess = (results) => ({
  type: FETCH_AUTOCOMPLETE_RESULTS,
  payload: results,
});

export const searchCitations = (query, userId) => async (dispatch) => {
  dispatch(searchRequest());
  try {
    const response = await backendClient.search(query, userId);
    const documents = await response.json();
    if (!response.ok)
      throw new Error(
        documents.detail || `Failed to search: HTTP status ${response.status}`
      );
    dispatch(searchSuccess(documents));
  } catch (error) {
    dispatch(
      searchFailure(error.message || "An error occurred during the search")
    );
  }
};

export const fetchRecentSearches =
  (userId = null, offset = 0) =>
  async (dispatch) => {
    try {
      const response = await backendClient.getRecentSearches(userId, offset);
      const searches = await response.json();
      dispatch(fetchRecentSearchesSuccess(searches));
      return searches; // Return searches to determine if more results are available
    } catch (error) {
      console.error("Error fetching recent searches:", error);
    }
  };

export const fetchAutocompleteResults = (query) => async (dispatch) => {
  try {
    const response = await backendClient.getAutocompleteResults(query);
    const results = await response.json();
    console.log("fetchAutocompleteResults - results", results);
    dispatch(fetchAutocompleteResultsSuccess(results));
  } catch (error) {
    console.error("Error fetching autocomplete results:", error);
  }
};
