import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  FETCH_RECENT_SEARCHES,
  FETCH_AUTOCOMPLETE_RESULTS,
  RESET_SEARCH_STORE,
} from "../actions/types";

const initialState = {
  loading: false,
  citations: [],
  error: null,
  recentSearches: [],
  autocompleteResults: [],
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return { ...state, loading: true, error: null, citations: [] };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        citations: action.payload,
        error: null,
      };
    case SEARCH_FAILURE:
      return { ...state, loading: false, citations: [], error: action.payload };
    case FETCH_RECENT_SEARCHES:
      return {
        ...state,
        autocompleteResults: [...state.autocompleteResults, ...action.payload],
      };
    case FETCH_AUTOCOMPLETE_RESULTS:
      return { ...state, autocompleteResults: action.payload };
    case RESET_SEARCH_STORE:
      return initialState;
    default:
      return state;
  }
};

export default searchReducer;
