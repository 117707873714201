import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  Search as SearchIcon,
  Chat as ChatIcon,
  Settings as SettingsIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { ReactComponent as Logo } from "../assets/logo.svg"; // Import the SVG as a React component
import { Link, useLocation } from "react-router-dom";
import { colors } from "../utils/colors";
import { setOpenNavBar } from "../redux/actions/navbarAction";

const Navbar = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.navbar.isOpen); // Get the open state from Redux
  const location = useLocation(); // Hook to get the current route path

  const toggleDrawer = () => {
    dispatch(setOpenNavBar(!open)); // Dispatch action to toggle the navbar open state
  };

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, link: "/" },
    { text: "Search", icon: <SearchIcon />, link: "/search" },
    { text: "Conversation", icon: <ChatIcon />, link: "/conversation" },
  ];

  const isItemSelected = (path) =>
    location.pathname === path ||
    location.pathname.split("/")[1] === path.split("/")[1];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: open ? 240 : 60,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? 240 : 60,
          transition: "width 0.3s",
          overflowX: "hidden",
          backgroundColor: colors.black, // Use black background
          color: colors.white,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // Keep space between top items and bottom settings
        },
      }}
    >
      <Box>
        <List>
          {/* Top icon linking to LandingScreen */}
          <ListItem
            component={Link}
            to="/"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              padding: open ? "16px" : "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: "white", // White circle background
                borderRadius: "50%", // Circular shape
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Logo
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </div>
          </ListItem>

          <ListItem
            onClick={toggleDrawer}
            sx={{
              justifyContent: open ? "flex-end" : "center",
              padding: open ? "8px 16px" : "8px 0",
            }}
          >
            <IconButton>
              {open ? (
                <CloseIcon sx={{ color: colors.white }} /> // Use Close icon when open
              ) : (
                <MenuIcon sx={{ color: colors.white }} /> // Use Menu icon when closed
              )}
            </IconButton>
          </ListItem>

          {menuItems.map((item, index) => (
            <Tooltip
              title={item.text}
              key={index}
              placement="right"
              disableHoverListener={open}
            >
              <ListItem
                component={Link}
                to={item.link}
                sx={{
                  justifyContent: open ? "initial" : "center",
                  padding: open ? "8px 16px" : "8px 0",
                  backgroundColor: isItemSelected(item.link)
                    ? colors.gray_3
                    : "transparent", // Use blue background if selected
                  color: isItemSelected(item.link) ? colors.white : colors.gray, // White text if selected, dark gray if not
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                    color: isItemSelected(item.link)
                      ? colors.white
                      : colors.gray, // White icon if selected, gray if not
                    padding: open ? "0 8px" : "0", // Add padding for open state
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.text}
                    sx={{
                      color: isItemSelected(item.link)
                        ? colors.white
                        : colors.gray, // White text if selected, gray if not
                    }}
                  />
                )}
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </Box>

      {/* Settings placed at the bottom */}
      <Box>
        <Tooltip title="Settings" placement="right" disableHoverListener={open}>
          <ListItem
            component={Link}
            to="/settings"
            sx={{
              justifyContent: open ? "initial" : "center",
              padding: open ? "8px 16px" : "8px 0",
              backgroundColor: isItemSelected("/settings")
                ? colors.gray_3
                : "transparent", // Blue background if selected
              color: isItemSelected("/settings") ? colors.white : colors.gray, // White text if selected, gray if not
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: "center",
                color: isItemSelected("/settings") ? colors.white : colors.gray, // White icon if selected, gray if not
                padding: open ? "0 8px" : "0", // Add padding for open state
              }}
            >
              <SettingsIcon />
            </ListItemIcon>
            {open && (
              <ListItemText
                primary="Settings"
                sx={{
                  color: isItemSelected("/settings")
                    ? colors.white
                    : colors.gray, // White text if selected, gray if not
                }}
              />
            )}
          </ListItem>
        </Tooltip>
      </Box>
    </Drawer>
  );
};

export default Navbar;
