import React, { useEffect, useState } from "react";
import { CircularProgress, Box, IconButton } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp"; // Import GetAppIcon from Material-UI
import useDocumentStore from "../../screens/ChatScreen/components/useDocumentStore";
import { ReactComponent as Logo } from "../../assets/logo.svg"; // Import SVG as a React component
import { useSnackbar } from "notistack";
import html2pdf from "html2pdf.js";
import { backendClient } from "../../api/backend";

export default function HtmlViewer() {
  const { loading, setLoading, selectedCitation } = useDocumentStore();
  const { enqueueSnackbar } = useSnackbar();

  const handleExport = () => {
    if (selectedCitation?.url) {
      fetch(selectedCitation.url)
        .then((response) => response.text())
        .then((htmlContent) => {
          // Create a temporary container element to hold the HTML content
          const tempElement = document.createElement("div");
          tempElement.innerHTML = htmlContent;

          // Use html2pdf to convert HTML to PDF and download it
          const options = {
            filename: "exported_file.pdf",
            jsPDF: { unit: "pt", format: "a4" },
            html2canvas: { scale: 2 }, // Adjust scale for better quality
            pagebreak: { mode: ["avoid-all", "css", "legacy"] }, // Optional: Better page break handling
          };

          html2pdf()
            .set(options)
            .from(tempElement)
            .save()
            .catch((error) => {
              console.error("Error generating PDF:", error);
              enqueueSnackbar("Failed to generate PDF. Please try again.", {
                variant: "error",
              });
            });
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
          enqueueSnackbar("Failed to fetch content. Please try again.", {
            variant: "error",
          });
        });
    }
  };

  const [selectedCitationUrl, setSelectedCitationUrl] = useState(null);

  useEffect(() => {
    const fetch_fn = async () => {
      const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME;
      const blobName = selectedCitation?.file_name;
      // const blobName = "22837.html";

      const data = await backendClient.getDocumentSas(containerName, blobName);

      console.log("URL", data.blob_url_with_sas);
      setSelectedCitationUrl(data.blob_url_with_sas);
    };

    if (selectedCitation?.file_name) {
      fetch_fn();
    }

    // setSelectedCitationUrl;
  }, [selectedCitation]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Use column to have a header and content below it
        height: "100%", // Full height
        width: "100%", // Full width
        padding: "16px",
      }}
    >
      {/* Header with Export Icon */}
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end", // Align the export button to the right
          alignItems: "center",
          paddingBottom: "8px",
        }}
      >
        {selectedCitation?.url && (
          <IconButton onClick={handleExport} aria-label="export">
            <GetAppIcon /> 
          </IconButton>
        )}
      </Box> */}

      {/* Content Area */}
      {selectedCitationUrl ? (
        <>
          {loading && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // position: "absolute", // Ensure it's overlaid on top
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.7)", // Light overlay
                zIndex: 10, // Ensure it's above other content
              }}
            >
              <CircularProgress size={50} />
            </Box>
          )}
          <iframe
            title="document"
            src={selectedCitationUrl}
            width="100%"
            height="100%"
            sandbox="allow-same-origin allow-scripts allow-popups"
            style={{
              border: "none",
              display: loading ? "none" : "block", // Hide iframe during loading
            }}
            onLoad={() => setLoading(false)}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Logo
            style={{
              width: "120px",
              height: "120px",
            }}
          />
          <Box
            sx={{
              marginTop: 2,
              fontSize: "16px",
              color: "#333",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              textAlign: "center",
            }}
          >
            {"Choisir un document à afficher"}
            <br />
            {"depuis la conversation."}
          </Box>
        </Box>
      )}
    </Box>
  );
}
