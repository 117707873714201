import { ROLE } from "./constants";
import _ from "lodash";
import { documentColors } from "./colors"; // Import the document colors
import moment from "moment";

export const windowWidth = window.innerWidth;
export const windowHeight = window.innerHeight;

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export const getCitations = (message) => {
  let citations = [];
  if (message.role === ROLE.ASSISTANT) {
    const sub_processes = message.sub_processes;
    if (sub_processes && sub_processes.length > 0) {
      const metadata_maps = sub_processes
        .map((sp) => sp.metadata_map)
        .filter((m) => !!m);
      const sub_questions = metadata_maps
        .map((m) => m?.sub_question)
        .filter((sq) => !!sq);
      const uniqueIds = {};
      const citationsList = sub_questions.map((sq) => sq?.citations || []);
      citations = citationsList
        .reduce((acc, curr) => [...acc, ...curr], [])
        .filter((obj) => {
          if (!uniqueIds[obj?.file_name]) {
            uniqueIds[obj?.file_name] = true;
            return true;
          }
          return false;
        });
    }
  }
  return citations;
};

export const formatDateWithTimezoneOffset = (dateString) => {
  // Create a moment object from the date string
  const date = moment(dateString);

  // Format the date including the timezone offset
  // 'Z' in the format string includes the offset from UTC in +hh:mm or -hh:mm format
  return date.format("DD-MM-YYYY HH:mm:ss");
};

export const fromBackendDocumentToFrontend = (backendDocuments) => {
  // Sort by 'created_at' so that the de-dupe filter later keeps the oldest duplicate docs
  backendDocuments = _.sortBy(backendDocuments, "created_at");

  let frontendDocs = backendDocuments
    .filter((backendDoc) => "sec_document" in backendDoc.metadata_map)
    .map((backendDoc, index) => {
      // Define the color index, ensuring a maximum of 10 colors
      const colorIndex = index < 10 ? index : 0;

      return {
        id: backendDoc.id,
        url: backendDoc.url,
        short_title: backendDoc.metadata_map.adv_document.short_title,
        long_title: backendDoc.metadata_map.adv_document.long_title,
        file_name: backendDoc.metadata_map.adv_document.file_name,
        new_name: backendDoc.metadata_map.adv_document.new_name,
        date: backendDoc.metadata_map.adv_document.date,
        translated: backendDoc.metadata_map.adv_document.translated,
        ar_pr: backendDoc.metadata_map.adv_document.ar_pr,
        fr_pr: backendDoc.metadata_map.adv_document.fr_pr,
        doc_type: backendDoc.metadata_map.adv_document.doc_type,
        color: documentColors[colorIndex],
        // Add any additional fields like 'quarter' if needed
      };
    });

  // De-duplication logic to avoid duplicate documents based on a custom key
  const getDocDeDupeKey = (doc) => `${doc.ticker}`;

  frontendDocs = _.chain(frontendDocs)
    .sortBy(getDocDeDupeKey)
    .sortedUniqBy(getDocDeDupeKey)
    .value();

  return frontendDocs;
};
